<template>
  <div ref="bluePrintRef" :style="slidePosition" class="h-100 z-5 bluePrintRef" style="width:100%;">
    <img src="images/Slide21/2010-ELECTRONS-FOR-THE-FUTURE-TRIUMF.png" class="position-absolute "
      aria-label="Table elements" style="bottom:-20%;width:100%" />
  </div>
</template>

<script>
import anime from "animejs"
import { normalize } from "@/utils/ranges"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  mounted() { },
  methods: {
    blueTableMove(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 0
      })

      animation
        .add({
          targets: ".bluePrintRef",
          left: ["200%", "100%"],
          duration: 600,
          delay: 300,
          easing: "easeInOutQuad"
        })
        .add({
          targets: ".bluePrintRef",
          left: ["100%", "-110%"],
          duration: 600,
          delay: 300,
          easing: "linear"
        })
      /* let progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress) */

      animation.seek(animation.duration * percentage)
    },
    bluePrintRef(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 200
      })

      animation.add({
        targets: ".bluePrintRef",
        opacity: [0, 1],
        duration: 600,
        delay: 0,
        easing: "easeInOutSine"
      })
      var progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress)
      //animation.seek(animation.duration * percentage)
    }
  },
  watch: {
    innerPosition() {
      this.bluePrintRef(this.innerPosition)
    }
  }
}
</script>
<style lang="scss" scoped>
$star-color-A: white;
$star-color-B: rgba(255, 255, 255, 0.1);

#sky {
  transition: all 3s ease;
  transform: translateZ(0);
}
</style>
